import {
  Alert,
  Box,
  type BoxProps,
  Button,
  Collapse,
  Grid,
  type ButtonProps as IButtonProps,
  IconButton,
  Paper,
  type PaperProps,
  Skeleton,
  Typography,
} from '@mui/material'
import {
  type EImageType,
  compressAccurately,
  dataURLtoImage,
  filetoDataURL,
} from 'image-conversion'
import { useCallback, useEffect, useMemo, useState } from 'react'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import EXIF from 'exifreader'
import dayjs from 'dayjs'

import MediaPermissionErrorViewer, { type PermissionError } from '@c/media-permission-error'
import { HybridCameraEvent, type ImageFileType } from '@util/constants'
import { useHybridToggles } from '@hooks/hybrid-context'
import { getLogToServerRequest } from '@util/request'
import { uniqueId } from '@util/functions'
import '@lib/hybrid-camera'

const styles = {
  photoItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  root: {
    height: 300,
    position: 'relative',
    borderRadius: 1,
  },
}

interface ExifData {
  GPSLatitudeRef?: string
  GPSLatitude?: string
  GPSLongitudeRef?: string
  GPSLongitude?: string
  DateTime?: string
}

export interface ImageObject {
  blob: Blob
  file: string
  name: string
  metaData: ExifData
  type: string
}

export function convertGPS(arr: number[][]): string {
  return `${arr[0][0] / arr[0][1]},${arr[1][0] / arr[1][1]},${arr[2][0] / arr[2][1]}`
}
export async function getPhotoUploadData(
  imageObject: Pick<ImageObject, 'blob' | 'name' | 'metaData'>,
  fileType?: ImageFileType,
): Promise<ImagePayload> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    const { blob, name, metaData } = imageObject
    const dateString = getIsoDateString()

    try {
      reader.onloadend = () => {
        resolve({
          fileName: name,
          image: reader.result as string,
          passedLatitude: metaData?.GPSLatitude,
          passedLatitudeRef: metaData?.GPSLatitudeRef,
          passedLongitude: metaData?.GPSLongitude,
          passedLongitudeRef: metaData?.GPSLongitudeRef,
          passedLocalTime: dateString,
          fileType,
          passedDateTaken: ENV_NAME === 'Development' ? dateString : metaData?.DateTime,
        })
      }
      reader.readAsDataURL(blob)
    } catch (err) {
      reject(err)
    }
  })
}
export function base64ToBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
  // eslint-disable-next-line deprecation/deprecation
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })

  return blob
}
export function getBlobUrl(blob: Blob): string {
  const urlCreator = window.URL || window.webkitURL

  return urlCreator.createObjectURL(blob)
}
export async function getCompressFile(
  file: Blob,
  maxSize = 800,
  compressToKb = 512,
  quality = 0.9,
): Promise<Blob> {
  const dataURL = await filetoDataURL(file)
  const image = await dataURLtoImage(dataURL)

  const srcWidth = image.width
  const srcHeight = image.height
  const ratio = Math.min(maxSize / srcWidth, maxSize / srcHeight)
  const actualKb = file.size / 1000
  // This is to force a resize if the ratio is smaller than one
  const size = ratio < 1 && actualKb < compressToKb ? actualKb - 10 : compressToKb
  const imageBlob = await compressAccurately(file, {
    size,
    quality,
    type: file.type as EImageType,
    width: Math.round(srcWidth * ratio),
    height: Math.round(srcHeight * ratio),
  })

  return imageBlob
}
function getIsoDateString(): string {
  try {
    return dayjs().format()
  } catch (error) {
    if (ENV_NAME === 'Development') {
      console.warn('Fallback to Vanilla JS date string')
    }

    const newDate = new Date()
    const tZoneStr = newDate.toString().match(/([-+][0-9]+)\s/)
    const timezone = tZoneStr != null ? tZoneStr[1] : ''
    const month = (newDate.getMonth() + 1).toString()
    const year = newDate.getFullYear().toString()
    const day = newDate.getDate().toString()
    const hours = newDate.getHours().toString()
    const minutes = newDate.getMinutes().toString()
    const seconds = newDate.getSeconds().toString()

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezone}`
  }
}

interface ImagePlaceholderProps extends PaperProps {
  backgroundImage?: string
  backgroundSize?: string
  disabled?: boolean
  enabledHybridLib: boolean
  helperText?: string
  image?: ImageObject
  imageIndex: number
  maxSize: number
  onHybridImageAdd?: (image: IPicture, imageIndex: number) => void
  onImageAdd?: (event: React.ChangeEvent<HTMLInputElement>, imageIndex: number) => void
  onImageRemove?: (image: ImageObject, imageIndex: number) => void
  photoType: HybridCameraSetting
  quality: number
  showError?: boolean
}
function ImagePlaceholder({
  backgroundImage,
  backgroundSize,
  disabled,
  helperText,
  image,
  imageIndex,
  maxSize,
  onHybridImageAdd,
  onImageAdd,
  onImageRemove,
  photoType = 'default',
  quality,
  showError,
  enabledHybridLib,
  ...other
}: ImagePlaceholderProps): React.JSX.Element | null {
  const id = useMemo(() => {
    return uniqueId()
  }, [])
  const [permissionError, setPermissionError] = useState<PermissionError | false>()
  const [listening, setListening] = useState(false)
  const capture = photoType === 'profile' ? 'user' : 'environment'

  function handleImageAdd(event: React.ChangeEvent<HTMLInputElement>): void {
    if (onImageAdd) onImageAdd(event, imageIndex)
  }
  function handleImageRemove(): void {
    if (onImageRemove && image) onImageRemove(image, imageIndex)
  }
  function handleImageClick(event: React.MouseEvent<HTMLInputElement>): void {
    if (enabledHybridLib) {
      const log = getLogToServerRequest()
      event.preventDefault()
      setListening(true)

      const payload = {
        type: photoType,
        showOverlay: true,
        maxDimension: maxSize,
        compressionPercentage: quality,
        megadata: [
          'coordinates',
          'DateTime',
          'GPSLatitude',
          'GPSLatitudeRef',
          'GPSLongitude',
          'GPSLongitudeRef',
          'position',
          'resolution',
          'timestamp',
        ],
      }

      log('HybridCameraRequest', JSON.stringify(payload))

      HybridCamera.takePicture(photoType, true, maxSize, quality, [
        'coordinates',
        'DateTime',
        'GPSLatitude',
        'GPSLatitudeRef',
        'GPSLongitude',
        'GPSLongitudeRef',
        'position',
        'resolution',
        'timestamp',
      ])
    }
  }

  useEffect(() => {
    const addHybridPicture = ({ detail }: CustomEvent<IPicture>): void => {
      if (onHybridImageAdd) onHybridImageAdd(detail, imageIndex)
      setListening(false)
    }

    if (listening) {
      window.addEventListener(HybridCameraEvent.DidTakePicture, addHybridPicture)
    }

    return () => {
      window.removeEventListener(HybridCameraEvent.DidTakePicture, addHybridPicture)
    }
  }, [imageIndex, listening, onHybridImageAdd])

  useEffect(() => {
    async function checkPermission(): Promise<void> {
      try {
        const status = await navigator.permissions.query({ name: 'camera' as PermissionName })

        if (status.state === 'denied') {
          try {
            await navigator.mediaDevices.getUserMedia({ video: true })

            setPermissionError(false)
          } catch (error) {
            setPermissionError(error as Error)
          }
        } else {
          setPermissionError(false)
        }
      } catch (error) {
        setPermissionError(false)
      }
    }

    // Check if the Permission API and MediaDevices exists
    // @ts-expect-error These apis might not be availible in the native app
    if (navigator?.permissions?.query && navigator?.mediaDevices?.getUserMedia) {
      checkPermission()
    } else {
      setPermissionError(false)
    }
  }, [])

  if (image) {
    return (
      <Paper
        sx={{
          ...styles.root,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: '100%',
          maxWidth: 400,
          borderColor: 'error.dark',
          borderStyle: showError ? 'solid' : 'none',
        }}
        {...other}
      >
        <Box display='block' width='100%' height={270} px={1} pt={1}>
          <img
            src={image.file}
            alt={image.name}
            style={{ width: '100%', height: '100%', objectFit: 'scale-down' }}
          />
        </Box>
        <Box display='flex' alignItems='center' width='100%'>
          <Typography noWrap align='center' style={{ flexGrow: 1 }}>
            {image.name}
          </Typography>
          {!disabled && (
            <IconButton onClick={handleImageRemove} size='small'>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Paper>
    )
  } else if (permissionError != null) {
    if (permissionError) {
      return <MediaPermissionErrorViewer error={permissionError} />
    }

    return (
      <>
        <input
          accept='image/*'
          type='file'
          id={id}
          capture={capture}
          value=''
          style={{ display: 'none' }}
          onChange={handleImageAdd}
          disabled={disabled}
          onClick={handleImageClick}
        />
        <Box component='label' htmlFor={id} sx={{ width: '100%', maxWidth: 400 }}>
          <Box
            sx={{
              ...styles.root,
              cursor: disabled ? 'default' : 'pointer',
              backgroundColor: 'background.paperHighlight',
              '&:hover': {
                '& .MuiSvgIcon-root': {
                  color: 'primary.main',
                  opacity: 0.8,
                },
              },
            }}
          >
            {!!backgroundImage && (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    borderRadius: 1,
                    background: `url('${backgroundImage}')`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize,
                    opacity: 0.5,
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    borderRadius: 1,
                    boxShadow: 'inset 0 0 40px 20px rgba(0, 0, 0, 0.5)',
                  }}
                />
              </>
            )}
            <Skeleton
              animation={disabled ? false : 'wave'}
              variant='rectangular'
              height={300}
              sx={{ borderRadius: 1 }}
            />
            <Box
              position='absolute'
              top={0}
              bottom={0}
              width='100%'
              padding={2}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderRadius: 1,
                  padding: 2,
                  backgroundColor: backgroundImage ? 'rgba(0, 0, 0, 0.45)' : undefined,
                }}
              >
                {!!helperText && (
                  <Typography align='center' sx={{ color: 'common.white' }}>
                    {helperText}
                  </Typography>
                )}

                {!disabled && (
                  <AddCircleIcon
                    sx={{
                      height: 75,
                      width: 75,
                      color: 'primary.light',
                      opacity: 0.6,
                      marginTop: 1,
                      marginBottom: 1,
                    }}
                  />
                )}

                <Typography align='center' color='common.white'>
                  {disabled ? '' : 'Click to Add Photo'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return null
}

interface AdditionalImageProps extends BoxProps {
  disabled?: boolean
  image: ImageObject
  imageIndex: number
  onImageRemove?: (image: ImageObject, imageIndex: number) => void
}
function AdditionalImage({
  disabled = false,
  image,
  imageIndex,
  onImageRemove,
  ...other
}: AdditionalImageProps): React.JSX.Element {
  function handleImageRemove(): void {
    if (onImageRemove && image) onImageRemove(image, imageIndex)
  }

  return (
    <Box
      {...other}
      sx={{
        ...other.sx,
        maxWidth: {
          xs: 'calc(50% - 4px)',
          md: 'calc(25% - 6px)',
          lg: 'calc(20% - 8px)',
        },
      }}
    >
      <Box display='block' width='100%' px={1} pt={1} sx={{ height: 125 }}>
        <Box
          component='img'
          src={image.file}
          alt={image.name}
          sx={{ width: '100%', height: '100%', objectFit: 'scale-down' }}
        />
      </Box>
      <Box display='flex' width='100%' alignItems='center'>
        <Typography noWrap sx={{ flexGrow: 1, px: 1, maxWidth: '100%' }}>
          {image.name}
        </Typography>
        {!disabled && (
          <IconButton disabled={disabled} size='small' onClick={handleImageRemove}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export enum ImageUploadContext {
  Image = 'image',
  Front = 'front',
  Back = 'back',
  Headshot = 'headshot',
}
interface ImageUploaderProps extends Omit<BoxProps, 'onChange'> {
  backgroundImage?: string | string[]
  backgroundSize?: 'cover' | 'contain'
  ButtonProps?: IButtonProps
  compressSize?: number
  disabled?: boolean
  disablePromoteOnRemove?: boolean
  helperText?: string | string[]
  imageErrors?: string[]
  maxImages?: number
  maxSize?: number
  minImages?: number
  onChange?: (image: ImageObject[], minImagesMet: boolean) => void
  quality?: number
  showSkeleton?: boolean
  context: ImageUploadContext
}
export default function ImageUploader({
  backgroundImage,
  backgroundSize = 'cover',
  ButtonProps,
  compressSize = 512,
  disabled,
  disablePromoteOnRemove,
  helperText,
  imageErrors = [],
  maxImages,
  maxSize = 800,
  minImages = 0,
  onChange,
  quality = 0.9,
  showSkeleton,
  context,
  ...other
}: ImageUploaderProps): React.JSX.Element {
  if (maxImages != null && minImages > maxImages) {
    throw Error('minImages can not be greater than maxImages.')
  }
  const { isHybidLibEnabled, getCameraSetting } = useHybridToggles()
  const [images, setImages] = useState<(ImageObject | undefined)[]>([])
  const [hasDup, setHasDup] = useState(false)
  const [hasCompressionError, setCompressionError] = useState(false)
  const [listening, setListening] = useState(false)
  const photoType = useMemo(() => {
    return getCameraSetting(context)
  }, [context, getCameraSetting])
  const capture = photoType === 'profile' ? 'user' : 'environment'
  const enabledHybridLib = useMemo(() => {
    return isHybidLibEnabled(context)
  }, [isHybidLibEnabled, context])
  const isDisabled = useMemo(() => {
    return disabled ? true : maxImages == null ? false : maxImages <= images.length
  }, [disabled, maxImages, images.length])
  const photosPerRow = useMemo(() => {
    return Math.floor(12 / minImages)
  }, [minImages])
  const setImageState = useCallback(
    (imgs: (ImageObject | undefined)[]): void => {
      let images = [...imgs]
      let minImagesMet = false

      if (maxImages != null) {
        images = images.slice(0, maxImages)
      }

      setImages(images)

      if (minImages > 0 && minImages <= images.length) {
        minImagesMet = images.slice(0, minImages).every((i) => i != null)
      }

      if (onChange) {
        const newImages = images.filter((img) => img != null)

        onChange(newImages, minImagesMet)
      }
    },
    [maxImages, minImages, onChange],
  )
  const handleHybridImageAdd = useCallback(
    (detail: IPicture, imageIndex?: number): void => {
      const log = getLogToServerRequest()
      const { data: base64, metadata: metaData } = detail
      log('HybridCameraResponse', JSON.stringify({ imageLength: base64.length, metaData }))
      let newImages = [...images]
      const blob = base64ToBlob(base64, 'image/jpeg')
      const file = getBlobUrl(blob)
      const image: ImageObject = {
        blob,
        file,
        metaData: {
          GPSLatitude: metaData?.coordinates?.latitude?.toString(),
          GPSLongitude: metaData?.coordinates?.latitude?.toString(),
          DateTime: metaData?.timestamp,
        },
        name: `${uniqueId('uhaul_image_')}.jpg`,
        type: blob.type,
      }

      if (imageIndex !== undefined) {
        newImages[imageIndex] = image
      } else {
        newImages = [...images, image]
      }

      setImageState(newImages)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images, setImageState],
  )

  // #region Image Functions
  async function handleImageFileChange(
    event: React.ChangeEvent<HTMLInputElement>,
    imageIndex?: number,
  ): Promise<void> {
    const fileList = event.target.files
    const files: File[] = fileList ? [...fileList] : []

    const compressedFiles = files
      .filter(checkForDups)
      .map(async (f) => await getCompressFile(f, maxSize, compressSize, quality))
    const metaData = files.map(async (f) => {
      return await getMetaData(f)
    })

    setHasDup(compressedFiles.length !== files.length)

    const cFiles = await Promise.allSettled(compressedFiles)
    const data = await Promise.allSettled(metaData)

    const mapFiles = cFiles.reduce((initValue: ImageObject[], promise, i) => {
      if (promise.status === 'fulfilled') {
        const file = getBlobUrl(promise.value)
        let metaData: ExifData = {}

        if (data[i].status === 'fulfilled') {
          metaData = data[i].value
        }

        initValue.push({
          blob: promise.value,
          file,
          name: files[i].name,
          type: files[i].type,
          metaData,
        })
      }

      return initValue
    }, [])

    let newImages = [...images]

    setCompressionError(cFiles.length !== mapFiles.length)

    if (imageIndex !== undefined && mapFiles.length) {
      newImages[imageIndex] = mapFiles[0]
    } else {
      newImages = [...images, ...mapFiles]
    }

    setImageState(newImages)
  }
  function checkForDups(file: File): boolean {
    return images.every((image) => {
      if (image && image.name === file.name) {
        return image.blob.size !== file.size
      }

      return true
    })
  }
  async function getMetaData(file: File): Promise<ExifData> {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        try {
          const result = event.target?.result as ArrayBuffer
          const tags = EXIF.load(result)
          const { GPSLatitudeRef, GPSLatitude, GPSLongitudeRef, GPSLongitude, DateTime } = tags

          const GPSLatitudeRefValue = Array.isArray(GPSLatitudeRef?.value)
            ? GPSLatitudeRef?.value[0]
            : GPSLatitudeRef?.value
          const GPSLongitudeRefValue = Array.isArray(GPSLongitudeRef?.value)
            ? GPSLongitudeRef?.value[0]
            : GPSLongitudeRef?.value

          resolve({
            GPSLatitudeRef: (GPSLatitudeRefValue as string) ?? '',
            GPSLatitude: GPSLatitude ? convertGPS(GPSLatitude.value as unknown as number[][]) : '',
            GPSLongitudeRef: (GPSLongitudeRefValue as string) ?? '',
            GPSLongitude: GPSLongitude
              ? convertGPS(GPSLongitude.value as unknown as number[][])
              : '',
            DateTime: DateTime?.description,
          })
        } catch (e) {
          reject(e)
        }
      }

      reader.readAsArrayBuffer(file)
    })
  }
  function handleImageRemove(img: ImageObject, index: number): void {
    const files = [...images]

    if (disablePromoteOnRemove && index < minImages) {
      files[index] = undefined
    } else {
      files.splice(index, 1)
    }

    setImageState(files)
  }
  function handleAdditionalImageClick(event: React.MouseEvent<HTMLInputElement>): void {
    if (enabledHybridLib) {
      event.preventDefault()
      setListening(true)
      HybridCamera.takePicture(photoType, true, maxSize, quality * 100, [
        'coordinates',
        'DateTime',
        'GPSLatitude',
        'GPSLatitudeRef',
        'GPSLongitude',
        'GPSLongitudeRef',
        'position',
        'resolution',
        'timestamp',
      ])
    }
  }
  // #endregion

  function getButtonText(): string {
    const fImages = images.filter((i) => i != null)

    if (!showSkeleton && !fImages.length) return 'Add Photos'

    if (
      (maxImages != null && !!minImages) ||
      (maxImages != null && maxImages > minImages && !!fImages.length)
    ) {
      return 'Add Additional Images'
    }

    return 'Add Photos'
  }
  function getPlaceholders(): React.ReactNode[] {
    const placeholders = []

    for (let i = 0; i < minImages; i++) {
      let text
      let bgImage

      if (Array.isArray(helperText) && i < helperText.length) {
        text = helperText[i]
      } else if (typeof helperText === 'string') {
        text = helperText
      }

      if (Array.isArray(backgroundImage) && i < backgroundImage.length) {
        bgImage = backgroundImage[i]
      } else if (typeof backgroundImage === 'string') {
        bgImage = backgroundImage
      }

      placeholders.push(
        <Grid
          item
          key={i}
          lg={photosPerRow > 3 ? photosPerRow : 3}
          md={photosPerRow > 4 ? photosPerRow : 4}
          sm={6}
          xs={12}
          sx={styles.photoItem}
        >
          <ImagePlaceholder
            disabled={disabled}
            imageIndex={i}
            helperText={text}
            image={images[i]}
            maxSize={maxSize}
            quality={quality * 100}
            photoType={photoType}
            showError={images[i] ? imageErrors.includes(images[i]?.name ?? '') : false}
            onImageAdd={handleImageFileChange}
            onImageRemove={handleImageRemove}
            onHybridImageAdd={handleHybridImageAdd}
            backgroundImage={bgImage}
            backgroundSize={backgroundSize}
            enabledHybridLib={enabledHybridLib}
          />
        </Grid>,
      )
    }

    return placeholders
  }
  function getAdditionImages(): React.ReactNode {
    const additionalImages = []
    const max = maxImages == null ? images.length : Math.min(images.length, maxImages)
    const min = showSkeleton ? minImages : 0

    for (let i = min; i < max; i++) {
      const hasError = images[i] ? imageErrors.includes(images[i]?.name ?? '') : false
      const image = images[i]

      if (image) {
        additionalImages.push(
          <AdditionalImage
            key={i}
            disabled={disabled}
            image={image}
            imageIndex={i}
            onImageRemove={handleImageRemove}
            sx={{
              borderRadius: 0.5,
              border: 1,
              borderColor: hasError ? 'error.dark' : 'background.shadowBox',
            }}
          />,
        )
      }
    }

    return additionalImages
  }
  function showAdditionButton(): boolean {
    if (maxImages == null && (minImages === 0 || (minImages > 0 && images.length >= minImages))) {
      return true
    }

    if (maxImages != null && maxImages > minImages && images.length >= minImages) {
      return true
    }

    if (!showSkeleton) {
      return true
    }

    return false
  }

  useEffect(() => {
    const addHybridPicture = ({ detail }: CustomEvent<IPicture>): void => {
      handleHybridImageAdd(detail)
      setListening(false)
    }

    if (listening) {
      window.addEventListener(HybridCameraEvent.DidTakePicture, addHybridPicture)
    }

    return () => {
      window.removeEventListener(HybridCameraEvent.DidTakePicture, addHybridPicture)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleHybridImageAdd, listening])

  return (
    <Box width='100%' {...other}>
      <Collapse in={hasDup} unmountOnExit mountOnEnter>
        <Alert
          sx={{ mb: 1 }}
          severity='error'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setHasDup(false)
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          One of your uploaded file(s) was a duplicate.
        </Alert>
      </Collapse>
      <Collapse in={hasCompressionError} unmountOnExit mountOnEnter>
        <Alert
          sx={{ mb: 1 }}
          severity='error'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setCompressionError(false)
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          One of your uploaded file(s) unsuccessfully compressed.
        </Alert>
      </Collapse>
      <Box display='flex' flexDirection='column'>
        {showSkeleton && minImages > 0 && (
          <Grid container spacing={1}>
            {getPlaceholders()}
          </Grid>
        )}
        {(!showSkeleton || images.length > minImages) && (
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'wrap',
              justifyContent: 'space-evenly',
              gap: 1,
              mb: 1,
            }}
          >
            {getAdditionImages()}
          </Box>
        )}
        <Collapse in={showAdditionButton()}>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Button
              component='label'
              role={undefined}
              tabIndex={-1}
              startIcon={<PhotoCameraIcon />}
              {...ButtonProps}
              disabled={isDisabled}
              sx={{ mt: images.length ? 1 : 0 }}
            >
              {getButtonText()}
              <Box
                component='input'
                accept='image/*'
                type='file'
                multiple={maxImages == null || maxImages > 1}
                capture={capture}
                value=''
                style={{ display: 'none' }}
                onClick={handleAdditionalImageClick}
                onChange={handleImageFileChange}
                disabled={isDisabled}
                sx={{
                  clip: 'rect(0 0 0 0)',
                  clipPath: 'inset(50%)',
                  height: 1,
                  overflow: 'hidden',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  whiteSpace: 'nowrap',
                  width: 1,
                }}
              />
            </Button>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}
